#normal-table-17 {
    border: 1px solid #ddd;
}

#normal-table-17 tr {
    border-bottom: 1px solid #ddd;
}

#normal-table-17 {
    border-collapse: collapse;
    width: 100%;
}

#normal-table-17 td {
    text-align: left;
    padding-top : 4px;
    padding-bottom : 4px;
    padding-left: 30px;
}

#td-total {
    text-align: center;
}

.cus-th{
    text-align: left;
    padding-left: 30px;
}
.cus-td {
    text-align: center;
    font-weight: bold;
}

button {
    background-color: #e7e7e7; color: black;
    border-radius: 4px;
    padding-right : 10px;
    padding-left : 10px;
    font-weight: bold;
    margin-right: 1px;
}

button:hover {
    background-color: #b7afaf; /* Green */
    color: black;
}

.mobile-table tbody:nth-child(even){background-color: #f2f2f2;}

.mobile-table. tbody:hover {background-color: #ddd;}


.mobile-table {
    display : none;
}

@media only screen and (max-width: 768px) {
    .mobile-table{
        display : block;
    }
    .mobile-table tbody td{
        width : 100% !important;
    }

    .mobile-table tbody td {
        text-align: right;
        padding-right: 10px;
    }

    .mobile-table tbody th {
        text-align: left;
        padding-left: 10px;
    }
    #order-heading{
        line-height: 1.6;
        font-size: 14px;
        text-align: justify;
        text-justify: inter-word;
    }
    #normal-table-17 td {
        padding-top : 4px;
        padding-bottom : 4px;
        padding-left: 10px;
    }

    .cus-th{
        padding-left: 10px;
    }

    .cus-td {
        text-align: right;
    }
}