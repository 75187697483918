

#table-6 {
    border: 1px solid #ddd;
}

#table-6 tr {
    border-bottom: 1px solid #ddd;
}

#table-6 {
    border-collapse: collapse;
    width: 100%;
}

#table-6, th, td {
    text-align: center;
}

#table-6 td {
    padding-top : 4px;
    padding-bottom : 4px;
}

button {
    background-color: #e7e7e7; color: black;
    border-radius: 4px;
    padding-right : 10px;
    padding-left : 10px;
    font-weight: bold;
    margin-right: 1px;
}

button:hover {
    background-color: #b7afaf; /* Green */
    color: black;
}

.mobile-table tbody:nth-child(even){background-color: #f2f2f2;}

.mobile-table. tbody:hover {background-color: #ddd;}


.mobile-table {
    display : none;
}

@media only screen and (max-width: 768px) {
    .mobile-table{
        display : block;
    }
    .mobile-table tbody td{
        width : 100% !important;
    }

    .mobile-table tbody td {
        text-align: right;
        padding-right: 10px;
    }

    .mobile-table tbody th {
        text-align: left;
        padding-left: 10px;
    }
    #table-6 {
        display : none;
    }
}